import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import logo from "../images/whitelogo.svg";
import Step from "../components/Step";
import { useLocation } from "react-router-dom";
import { useStore } from "../utils/store";
import GetHelpModal from "../components/GetHelpModal";
import { isEmpty } from "lodash";
import useAuth from "../auth/useAuth";
import ApplicantDrawer from "../pages/quotes/ApplicantDrawer";
import { useGetQuotes } from "../hooks/useGetQuotes";
import { useGetAdminQuotes } from "../hooks/useGetAdminQuotes";
import { useSearchParams } from "react-router-dom";
import { useGetMLQuotes } from "../hooks/useGetMLQuotes";
import { useGetSearchQuotes } from "../hooks/useGetSearchQuotes";
import { mobile } from "../styles/responsive";
import AccountIcon from "../images/account.svg";
import BlueAccountIcon from "../images/blueaccount.svg";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [displayHelp, setDisplayHelp] = useState(false);
  const location = useLocation();
  const step = useStore((state) => state.step);
  const formErrors = useStore((state) => state.formErrors);
  const adminSearchEmail = useStore((state) => state.searchEmail);
  const start = useStore((state) => state.start);
  const displayErrorText =
    !isEmpty(formErrors.businessInfo) ||
    !isEmpty(formErrors.typeBusiness) ||
    !isEmpty(formErrors.policyQuestions) ||
    !isEmpty(formErrors.insuranceQuestions);
  const { email, getAdmin } = useAuth();
  const admin = getAdmin();

  const handleHelp = () => {
    setDisplayHelp(!displayHelp);
  };

  const [searchParams] = useSearchParams();
  const searchEmail = searchParams.get("email");
  const path = location.pathname;

  const adminDrawer = path.includes("/quotes") && admin;
  const userDrawer = path.includes("/quotes") && !admin;

  const {
    data: quotesData,
    isLoading,
    error,
  } = useGetQuotes(email, userDrawer);
  const { data: searchQuotesData } = useGetSearchQuotes(
    adminSearchEmail,
    adminDrawer
  );
  const { data: adminQuotesData } = useGetAdminQuotes(adminDrawer);

  const { data: mlQuotesData } = useGetMLQuotes();

  const displayStepper = path.includes("/application") ? true : false;

  const displayQuoteDrawer =
    path === "/quotes" && quotesData && !isLoading & !error;

  const displayMLQuoteDrawer =
    path.includes("/myquotes") && mlQuotesData && !isLoading & !error;

  const displayAdminDrawer =
    path.includes("/quotes") && adminQuotesData && searchEmail;

  const displaySearchDrawer =
    path === "/quotes" && searchQuotesData && adminSearchEmail !== "";

  const timerRef = useRef(null);

  const homeUrl =
    start.email === "" || path === "/getstarted"
      ? "https://especialty.com"
      : "/";

  useEffect(() => {
    if (path === "/quotes") {
      timerRef.current = setTimeout(() => setDisplayHelp(true), 120000);
      return () => clearTimeout(timerRef.current);
    }
  }, []);

  return (
    <Container>
      <div>
        <a href="https://mclureins.com">
          <Logo src={logo} alt="company logo" />
        </a>
        {start.email !== "" && (
          <Link to="/account">
            <AccountContainer>
              <Row>
                <AccountImage />
                <Details>
                  <AccountHeader>My Account</AccountHeader>
                  <AccountName>{email}</AccountName>
                </Details>
              </Row>
            </AccountContainer>
          </Link>
        )}
        {displaySearchDrawer && (
          <ApplicantDrawer
            application={searchQuotesData.application}
            limitsQuoted={searchQuotesData.limitsQuoted}
          />
        )}
        {displayAdminDrawer && (
          <ApplicantDrawer
            application={adminQuotesData.application}
            limitsQuoted={adminQuotesData.limitsQuoted}
          />
        )}
        {displayQuoteDrawer && (
          <ApplicantDrawer
            application={quotesData.application}
            limitsQuoted={quotesData.limitsQuoted}
          />
        )}
        {displayMLQuoteDrawer && (
          <ApplicantDrawer
            application={mlQuotesData.application}
            limitsQuoted={mlQuotesData.limitsQuoted}
          />
        )}
      </div>
      {displayStepper && (
        <>
          <Steps>
            <Step
              label="Business Information"
              name="businessInfo"
              size="big"
              position="top"
              active={step >= 1}
              step={1}
            />
            <Step
              label="Type of Business"
              name="typeBusiness"
              size="medium"
              active={step >= 2}
              step={2}
            />
            <Step
              label="Policy Questions"
              name="policyQuestions"
              size="medium"
              active={step >= 3}
              step={3}
            />
            <Step
              label="Insurance Questions"
              name="insuranceQuestions"
              size="small"
              active={step >= 4}
              step={4}
            />
            <Step
              label="Review"
              name="review"
              size="medium"
              position="bottom"
              active={step >= 5}
              step={5}
            />
          </Steps>
          {displayErrorText && (
            <ErrorContainer>
              <Dot />
              <DotText>Red dot indicates an error</DotText>
            </ErrorContainer>
          )}
        </>
      )}
      <Footer>
        <GetHelpModal closeModal={handleHelp} modalIsOpen={displayHelp} />
        <HelpButton onClick={() => handleHelp(!displayHelp)}>
          Need help? Ask an expert
        </HelpButton>
        <FinePrintContainer>
          <FinePrintLink
            href="https://especialty.com/terms-disclaimers/?_ga=2.129919621.800046540.1685029009-972524971.1683751862&_gac=1.5885703.1684186964.EAIaIQobChMIhunLqKX4_gIV7SStBh1W7A9eEAAYASAAEgKDq_D_BwE&_gl=1*lkv92v*_ga*OTcyNTI0OTcxLjE2ODM3NTE4NjI.*_ga_EHRP3K0EH1*MTY4NTAyOTAwOS4xMC4xLjE2ODUwMjkwMTQuNTUuMC4w"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms & Disclaimers
          </FinePrintLink>
          <MiddleFinePrint
            href="https://especialty.com/privacy-policy/?_ga=2.129919621.800046540.1685029009-972524971.1683751862&_gac=1.5885703.1684186964.EAIaIQobChMIhunLqKX4_gIV7SStBh1W7A9eEAAYASAAEgKDq_D_BwE&_gl=1*lkv92v*_ga*OTcyNTI0OTcxLjE2ODM3NTE4NjI.*_ga_EHRP3K0EH1*MTY4NTAyOTAwOS4xMC4xLjE2ODUwMjkwMTQuNTUuMC4w"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </MiddleFinePrint>
          <FinePrintLink
            href="https://especialty.com/license/?_ga=2.198135718.800046540.1685029009-972524971.1683751862&_gac=1.237830452.1684186964.EAIaIQobChMIhunLqKX4_gIV7SStBh1W7A9eEAAYASAAEgKDq_D_BwE&_gl=1*bndiel*_ga*OTcyNTI0OTcxLjE2ODM3NTE4NjI.*_ga_EHRP3K0EH1*MTY4NTAyOTAwOS4xMC4xLjE2ODUwMjkwMTQuNTUuMC4w"
            target="_blank"
            rel="noreferrer noopener"
          >
            Licenses
          </FinePrintLink>
        </FinePrintContainer>
      </Footer>
    </Container>
  );
};

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const AccountImage = styled.div`
  background-image: url(${AccountIcon});
  height: 28px;
  width: 28px;
  background-repeat: no-repeat;
  background-position: center top;

  &:hover {
    cursor: pointer;
    background-image: url(${BlueAccountIcon});
  }
`;

const Details = styled.div`
  margin-left: 10px;
`;

const AccountContainer = styled.div`
  color: ${(props) => props.theme.white};
  margin-left: 55px;

  // &:hover {
  //   cursor: pointer;
  //   color: ${(props) => props.theme.blue};
  // }
`;

const AccountHeader = styled.p`
  color: ${(props) => props.theme.white}
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

const AccountName = styled.p`
  color: ${(props) => props.theme.white}
  font-size: 18px;
  margin: 0;
`;

const Container = styled.div`
  background: ${(props) => props.theme.darkblue};
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;

  ${mobile} {
  }
`;

const Footer = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  ${mobile} {
    display: none;
  }
`;

const Logo = styled.img`
  margin: 60px 130px;

  ${mobile} {
    margin: 20px 30px 0px;
  }
`;

const Steps = styled.div``;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 30px;
  background-color: ${(props) => props.theme.red};
  margin: auto;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 175px;
  margin-left: 83px;
  margin-top: 10px;
`;

const DotText = styled.p`
  color: ${(props) => props.theme.red};
  font-size: 12px;
`;

const HelpButton = styled.button`
  background: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  padding: 12px 24px;
  border: none;
  border-radius: 27px;
  font-size: 16px;
  font-weight: 500;


  :hover {
    cursor pointer;
    background: ${(props) => props.theme.helpbutton};
  }
`;

const FinePrintLink = styled.a`
  font-size: 11px;
  color: ${(props) => props.theme.white};
  text-decoration: none;
`;

const MiddleFinePrint = styled.a`
  font-size: 11px;
  color: ${(props) => props.theme.white};
  text-decoration: none;
  border-right: ${(props) => `1px solid ${props.theme.white}`};
  border-left: ${(props) => `1px solid ${props.theme.white}`};
  padding: 0 6px 0 6px;
  margin: 0 6px 0 6px;
`;

const FinePrintContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

export default Sidebar;
